var citysHK = [
  {
    label: '香港',
    value: '999000',
    children: [
      {
        label: '北區',
        value: '999100',
        children: [
          {
            label: '北區',
            value: '999101',
          },
          {
            label: '打鼓嶺',
            value: '999102',
          },
          {
            label: '粉嶺',
            value: '999103',
          },
          {
            label: '古洞',
            value: '999104',
          },
          {
            label: '坪輋',
            value: '999105',
          },
          {
            label: '沙頭角',
            value: '999106',
          },
          {
            label: '上水',
            value: '999107',
          },
          {
            label: '文錦渡',
            value: '999108',
          },
        ],
      },
      {
        label: '長洲區',
        value: '999200',
        children: [
          {
            label: '長洲',
            value: '999201',
          },
        ],
      },
      {
        label: '大埔區',
        value: '999300',
        children: [
          {
            label: '大埔',
            value: '999301',
          },
          {
            label: '太和',
            value: '999302',
          },
        ],
      },
      {
        label: '大嶼山區',
        value: '999400',
        children: [
          {
            label: '昂坪',
            value: '999401',
          },
          {
            label: '貝澳',
            value: '999402',
          },
          {
            label: '長沙',
            value: '999403',
          },
          {
            label: '赤臘角',
            value: '999404',
          },
          {
            label: '大澳',
            value: '999405',
          },
          {
            label: '大澳碼頭',
            value: '999406',
          },
          {
            label: '大嶼山',
            value: '999407',
          },
          {
            label: '東涌',
            value: '999408',
          },
          {
            label: '馬灣',
            value: '999409',
          },
          {
            label: '梅窩',
            value: '999410',
          },
          {
            label: '梅窩碼頭',
            value: '999411',
          },
          {
            label: '石壁',
            value: '999412',
          },
          {
            label: '塘福',
            value: '999413',
          },
          {
            label: '天壇大佛',
            value: '999414',
          },
          {
            label: '香港廸士尼',
            value: '999415',
          },
          {
            label: '香港國際機場',
            value: '999416',
          },
          {
            label: '愉景灣',
            value: '999417',
          },
          {
            label: '竹篙灣',
            value: '999418',
          },
        ],
      },
      {
        label: '東區',
        value: '999500',
        children: [
          {
            label: '北角',
            value: '999501',
          },
          {
            label: '柴灣',
            value: '999502',
          },
          {
            label: '炮台山',
            value: '999503',
          },
          {
            label: '筲箕灣',
            value: '999504',
          },
          {
            label: '太古',
            value: '999505',
          },
          {
            label: '太古城',
            value: '999506',
          },
          {
            label: '天后',
            value: '999507',
          },
          {
            label: '西灣河',
            value: '999508',
          },
          {
            label: '小西灣',
            value: '999509',
          },
          {
            label: '杏花村',
            value: '999510',
          },
          {
            label: '鰂魚涌',
            value: '999511',
          },
        ],
      },
      {
        label: '觀塘區',
        value: '999600',
        children: [
          {
            label: '觀塘',
            value: '999601',
          },
          {
            label: '九龍灣',
            value: '999602',
          },
          {
            label: '藍田',
            value: '999603',
          },
          {
            label: '牛頭角',
            value: '999604',
          },
          {
            label: '順利',
            value: '999605',
          },
          {
            label: '秀茂坪',
            value: '999606',
          },
          {
            label: '油塘',
            value: '999607',
          },
        ],
      },
      {
        label: '黃大仙區',
        value: '999700',
        children: [
          {
            label: '彩虹',
            value: '999701',
          },
          {
            label: '慈雲山',
            value: '999702',
          },
          {
            label: '橫頭磡',
            value: '999703',
          },
          {
            label: '黃大仙',
            value: '999704',
          },
          {
            label: '樂富',
            value: '999705',
          },
          {
            label: '牛池灣',
            value: '999706',
          },
          {
            label: '新蒲崗',
            value: '999707',
          },
          {
            label: '鑽石山',
            value: '999708',
          },
        ],
      },
      {
        label: '九龍城區',
        value: '999800',
        children: [
          {
            label: '何文田',
            value: '999801',
          },
          {
            label: '紅磡',
            value: '999802',
          },
          {
            label: '黃埔',
            value: '999803',
          },
          {
            label: '九龍城',
            value: '999804',
          },
          {
            label: '九龍塘',
            value: '999805',
          },
          {
            label: '馬頭圍',
            value: '999806',
          },
          {
            label: '啟德',
            value: '999807',
          },
          {
            label: '土瓜灣',
            value: '999808',
          },
        ],
      },
      {
        label: '葵青區',
        value: '999900',
        children: [
          {
            label: '葵芳',
            value: '999901',
          },
          {
            label: '葵興',
            value: '999902',
          },
          {
            label: '葵涌',
            value: '999903',
          },
          {
            label: '葵涌貨櫃碼頭',
            value: '999904',
          },
          {
            label: '荔景',
            value: '999905',
          },
          {
            label: '青衣',
            value: '999906',
          },
          {
            label: '青衣嘉民領達中心',
            value: '999907',
          },
        ],
      },
      {
        label: '南區',
        value: '1000000',
        children: [
          {
            label: '薄扶林',
            value: '1000001',
          },
          {
            label: '赤柱',
            value: '1000002',
          },
          {
            label: '舂坎角',
            value: '1000003',
          },
          {
            label: '大潭',
            value: '1000004',
          },
          {
            label: '華富',
            value: '1000005',
          },
          {
            label: '黃竹坑',
            value: '1000006',
          },
          {
            label: '淺水灣',
            value: '1000007',
          },
          {
            label: '深水灣',
            value: '1000008',
          },
          {
            label: '石澳',
            value: '1000009',
          },
          {
            label: '香港仔',
            value: '1000010',
          },
          {
            label: '鴨脷洲',
            value: '1000011',
          },
        ],
      },
      {
        label: '南丫島區',
        value: '1000100',
        children: [
          {
            label: '南丫島',
            value: '1000101',
          },
        ],
      },
      {
        label: '坪洲區',
        value: '1000200',
        children: [
          {
            label: '坪洲',
            value: '1000201',
          },
        ],
      },
      {
        label: '荃灣區',
        value: '1000300',
        children: [
          {
            label: '大窩口',
            value: '1000301',
          },
          {
            label: '荃灣',
            value: '1000302',
          },
          {
            label: '深井',
            value: '1000303',
          },
          {
            label: '汀九',
            value: '1000304',
          },
        ],
      },
      {
        label: '沙田區',
        value: '1000400',
        children: [
          {
            label: '大圍',
            value: '1000401',
          },
          {
            label: '禾輋',
            value: '1000402',
          },
          {
            label: '火炭',
            value: '1000403',
          },
          {
            label: '九肚山',
            value: '1000404',
          },
          {
            label: '馬鞍山',
            value: '1000405',
          },
          {
            label: '馬料水',
            value: '1000406',
          },
          {
            label: '沙田',
            value: '1000407',
          },
          {
            label: '石門',
            value: '1000408',
          },
          {
            label: '小瀝源',
            value: '1000409',
          },
        ],
      },
      {
        label: '深水埗區',
        value: '1000500',
        children: [
          {
            label: '昂船洲',
            value: '1000501',
          },
          {
            label: '長沙灣',
            value: '1000502',
          },
          {
            label: '荔枝角',
            value: '1000503',
          },
          {
            label: '美孚',
            value: '1000504',
          },
          {
            label: '南昌',
            value: '1000505',
          },
          {
            label: '深水埗',
            value: '1000506',
          },
          {
            label: '石硤尾',
            value: '1000507',
          },
        ],
      },
      {
        label: '屯門區',
        value: '1000600',
        children: [
          {
            label: '大欖',
            value: '1000601',
          },
          {
            label: '大欖涌',
            value: '1000602',
          },
          {
            label: '藍地',
            value: '1000603',
          },
          {
            label: '龍鼓灘',
            value: '1000604',
          },
          {
            label: '掃管笏',
            value: '1000605',
          },
          {
            label: '屯門',
            value: '1000606',
          },
          {
            label: '屯門內河碼頭',
            value: '1000607',
          },
          {
            label: '小欖',
            value: '1000608',
          },
          {
            label: '新墟',
            value: '1000609',
          },
        ],
      },
      {
        label: '灣仔區',
        value: '1000700',
        children: [
          {
            label: '大坑',
            value: '1000701',
          },
          {
            label: '跑馬地',
            value: '1000702',
          },
          {
            label: '銅鑼灣',
            value: '1000703',
          },
          {
            label: '灣仔',
            value: '1000704',
          },
          {
            label: '香港會議展覽中心',
            value: '1000705',
          },
        ],
      },
      {
        label: '西貢區',
        value: '1000800',
        children: [
          {
            label: '寶琳',
            value: '1000801',
          },
          {
            label: '調景嶺',
            value: '1000802',
          },
          {
            label: '將軍澳',
            value: '1000803',
          },
          {
            label: '康城',
            value: '1000804',
          },
          {
            label: '坑口',
            value: '1000805',
          },
          {
            label: '清水灣',
            value: '1000806',
          },
          {
            label: '西貢',
            value: '1000807',
          },
        ],
      },
      {
        label: '油尖旺區',
        value: '1000900',
        children: [
          {
            label: '大角咀',
            value: '1000901',
          },
          {
            label: '尖沙咀',
            value: '1000902',
          },
          {
            label: '柯士甸',
            value: '1000903',
          },
          {
            label: '太子',
            value: '1000904',
          },
          {
            label: '旺角',
            value: '1000905',
          },
          {
            label: '油麻地',
            value: '1000906',
          },
          {
            label: '佐敦',
            value: '1000907',
          },
        ],
      },
      {
        label: '元朗區',
        value: '1001000',
        children: [
          {
            label: '八鄉',
            value: '1001001',
          },
          {
            label: '大棠',
            value: '1001002',
          },
          {
            label: '洪水橋',
            value: '1001003',
          },
          {
            label: '錦上路',
            value: '1001004',
          },
          {
            label: '錦田',
            value: '1001005',
          },
          {
            label: '林村',
            value: '1001006',
          },
          {
            label: '流浮山',
            value: '1001007',
          },
          {
            label: '落馬洲',
            value: '1001008',
          },
          {
            label: '米埔',
            value: '1001009',
          },
          {
            label: '牛潭尾',
            value: '1001010',
          },
          {
            label: '屏山',
            value: '1001011',
          },
          {
            label: '石崗',
            value: '1001012',
          },
          {
            label: '天水圍',
            value: '1001013',
          },
          {
            label: '廈村',
            value: '1001014',
          },
          {
            label: '新田',
            value: '1001015',
          },
          {
            label: '元朗',
            value: '1001016',
          },
        ],
      },
      {
        label: '中西區',
        value: '1001100',
        children: [
          {
            label: '半山',
            value: '1001101',
          },
          {
            label: '堅尼地城',
            value: '1001102',
          },
          {
            label: '金鐘',
            value: '1001103',
          },
          {
            label: '山頂',
            value: '1001104',
          },
          {
            label: '山頂盧吉道',
            value: '1001105',
          },
          {
            label: '上環',
            value: '1001106',
          },
          {
            label: '西環',
            value: '1001107',
          },
          {
            label: '西營盤',
            value: '1001108',
          },
          {
            label: '中環',
            value: '1001109',
          },
        ],
      },
    ],
  },
  
]

export default citysHK
