<!-- 新建/编辑收货地址 -->
<template>
  <div class="addAddress">
    <div class="bigbox">
      <div class="login-box">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
          :hide-required-asterisk="!0"
        >
          <div class="popup-title">
            <p v-text="$t('add.add_adderss')"></p>
            <img src="../assets/icon/delete.png" class="click" @click.stop="fnClose" />
          </div>
          <div class="popup-body" style="padding: 0 30rpx;">
            <el-form-item :label="$t('add.people')" prop="receiver">
              <el-input
                v-model="ruleForm.receiver"
                :placeholder="$t('add.write_people')"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('add.numb')" prop="phone">
              <el-input v-model="ruleForm.phone" :placeholder="$t('add.write_numb')" maxlength="15"></el-input>
            </el-form-item>
            <el-form-item :label="$t('add.adderss')" prop="area" class="f-popper-box">
              <el-cascader
                popper-class="popper-box"
                :options="options"
                clearable
                v-model="ruleForm.area"
                :placeholder="$t('add.write_adderss')"
                :props="{value:'label'}"
              ></el-cascader>
            </el-form-item>
            <el-form-item :label="$t('add.detail')" prop="address" maxlength="200">
              <el-input
                class="address-text"
                v-model="ruleForm.address"
                type="textarea"
                :placeholder="$t('add.write_detail')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="submit-box">
                <el-button type="primary" @click="submitForm('ruleForm')" v-text="$t('add.botton')"></el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, FormItem, Cascader } from 'element-ui'

// const citys = require('../assets/js/citys.js')
// const citysHk = require('../assets/js/citys-hk.js')

export default {
  name: 'AddAddress',
  data() {
    // let validMobile = (rule, value, callback) => {
    //   if (value == '' || value == undefined) {
    //     callback()
    //   } else {
    //     let reg = /[0-9]{11,15}/
    //     if (!reg.test(value)) {
    //       callback(new Error('电话格式有误'))
    //     } else {
    //       callback()
    //     }
    //   }
    // }
    return {
      ruleForm: {
        receiver: null,
        phone: null,
        area: null,
        address: null,
      },
      rules: {
        receiver: [
          {
            required: true,
            message: this.$t('add.input_people'),
            trigger: 'submit',
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t('add.input_numb'),
            trigger: 'submit',
          },
          // { validator: validMobile, trigger: 'submit' },
        ],
        area: [
          {
            required: true,
            message: this.$t('add.input_adderss'),
            trigger: 'submit',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('add.input_detail'),
            trigger: 'submit',
          },
        ],
      },
      options: [],
    }
  },
  props: {
    editAdderss: {
      type: Object,
    },
  },

  created() {
    var userinfo = sessionStorage.getItem('userinfo')
    userinfo = JSON.parse(userinfo)

    if (userinfo.local == 1) {
      this.options = require('../assets/js/citys.js').default
    } else {
      this.options = require('../assets/js/citys-hk.js').default
    }

    if (this.editAdderss) {
      this.ruleForm.receiver = this.editAdderss.name
      this.ruleForm.phone = this.editAdderss.mobile
      this.ruleForm.address = this.editAdderss.address
      this.ruleForm.area = [
        this.editAdderss.province,
        this.editAdderss.city,
        this.editAdderss.area,
      ]
    }
  },
  methods: {
    submitForm(formName) {
      var self = this
      this.$refs[formName].validate((valid) => {
        if (!valid) return
        if (self.editAdderss) self.httpEditAddress()
        else self.httpAddAddress()
      })
    },
    httpEditAddress() {
      var self = this
      self.common
        .httpPost('/api/user/editAddress', {
          id: self.editAdderss.id,
          mobile: self.ruleForm.phone,
          name: self.ruleForm.receiver,
          address: self.ruleForm.address,
          province: self.ruleForm.area[0],
          city: self.ruleForm.area[1],
          area: self.ruleForm.area[2],
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            var address = ret.data
            self.$emit('editAddressSuccess', address)
          }
        })
    },
    httpAddAddress() {
      var self = this
      self.common
        .httpPost('/api/user/addAddress', {
          mobile: self.ruleForm.phone,
          name: self.ruleForm.receiver,
          address: self.ruleForm.address,
          province: self.ruleForm.area[0],
          city: self.ruleForm.area[1],
          area: self.ruleForm.area[2],
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            var address = ret.data
            self.$emit('addAddressSuccess', address)
          }
        })
    },
    fnClose() {
      this.$emit('close')
    },
  },
  components: {
    'el-cascader': Cascader,
    'el-form': Form,
    'el-form-item': FormItem,
  },
}
</script>
<style>
.el-form-item__label {
  color: rgba(51, 51, 51, 1) !important;
}

/* .f-popper-box {
  border: 1px solid red!important;
  position: relative;
} */

.popper-box {
  left: 37% !important;
}

.address-text textarea {
  height: 80px;
  resize: none;
}
</style>

<style scoped>
.submit-box {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.submit-box button {
  width: 160px;
}

.bigbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.el-input {
  width: 250px;
}

.login-box {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 595px;
  width: 720px;
  background: #ffffff;
  border-radius: 2px;
}

.popup-body {
  padding-top: 21px;
}

.popup-title {
  height: 50px;
  background: rgba(247, 246, 246, 1);
  border-radius: 2px 2px 0px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.popup-title p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 24px;
  margin-left: 30px;
}

.popup-title img {
  height: 12px;
  width: 12px;
  margin-right: 30px;
}

.popup-body {
  margin: 0px 30px;
}
</style>